<template>
  <div>
    <v-text-field
      dense
      outlined
      autofocus
      counter
      :label="$t('data.code')"
      :error="!!apiError.code"
      :error-messages="apiError.code"
      maxlength="100"
      :rules="[
        v => $helper.validator.required(v),
      ]"
      v-model="formData.code"
    ></v-text-field>
  </div>
</template>

<script lang="babel" type="text/babel">
import uuidV4 from 'uuid/v4'
import listCreatePopupMixin from '@/components/list/listCreateButton/listCreatePopupMixin'
export default {
  mixins: [listCreatePopupMixin],
  data: () => ({
    formData: {
      code: uuidV4(),
    },
  }),
  computed: {

  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>